import React, {useState} from 'react'

//import nav data
import {navigation} from '../data';
//import icons 
import {XIcon} from '@heroicons/react/outline';
import {MenuAlt3Icon} from '@heroicons/react/outline';

//import framer motion
import { motion } from 'framer-motion';
//import Link
import { Link } from 'react-scroll'

function NavMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const circleVariants = {
    hidden: {
      scale: 0
    },
    visible: {
      scale: 180,
      transition: {
        type: 'spring',
        stiffness: 160,
        damping: 60,
      },
    },
  };

  const ulVariants = {
    hidden: {
      opacity: 0
    },
    visible:{
      opacity: 1,
      transition: {
        delay: 0.1
      },
    },
  };

  return (
    <nav className='relative'>
    {/* menu icon*/}
      <div onClick={()=> setIsOpen(true)} className='cursor-pointer text-white'>
        <MenuAlt3Icon className='w-8 h-8'/>
      </div>
      {/*circle */}
      <motion.div 
      variants={circleVariants}
      initial='hidden'
      animate={isOpen ? 'visible' : 'hidden'}
      className='w-4 h-4 rounded-full bg-accent fixed top-0 right-0'
      >
      </motion.div>
      {/*menu */}
      <motion.ul variants={ulVariants} initial='hidden' animate={isOpen? 'visible' : 'hidden'} className={`${isOpen? 'right-0' : '-right-full'} fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all duration-300 overflow-hidden`}>
      {/*close icon */}
      <div onClick={()=> setIsOpen(false)} className='cursor-pointer absolute top-8 right-8'>
      <XIcon className='w-8 h-8'/>
      </div>
      {
        navigation.map((item, index)=>{
          return ( <li key={index} className='mb-8'>
            <Link to={item.href}
            onClick={()=> setIsOpen(false)}
            smooth={true}
            duration={500}
            offset={-70}
            className='text-xl cursor-pointer capitalize'>
            {item.name}
            </Link>
          </li>
          );
        })
      }
      <li className='p-2'>
      <a href="https://github.com/1uckyswish" className='text-xl cursor-pointer capitalize text-black' target="_blank" rel="noreferrer">Github</a>
      </li>
          <li className='p-2'>
      <a href="https://www.linkedin.com/in/noel-guillen-blas-b63353257/" className='text-xl cursor-pointer capitalize text-black' target="_blank" rel="noreferrer">Linkedin</a>
      </li>
          <li className='p-2'>
      <a href="https://www.codewars.com/users/NoelGuillen" className='text-xl cursor-pointer capitalize text-black' target="_blank" rel="noreferrer">CodeWars</a>
      </li>
      </motion.ul>
    </nav>
  )
}

export default NavMobile